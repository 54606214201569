<alaris-card *alarisLet="(invoiceForm.get('operationType')?.value ||
			 			  invoiceForm.get('period')?.value) as hasFilter">
	<div *ngIf="tRows.length > 0 || tRows.length === 0 && hasFilter" class="table-management">
		<div data-testid="total">{{ totalTitle | translate }}: {{ total }}</div>
		<alaris-tabs-button [formControl]="invoiceForm.controls.operationType"
							[tabs]="tabs" data-testid="operationTypeTabs" size="md">
		</alaris-tabs-button>
		<alaris-input-date-range [addons]="true"
								 [formControl]="invoiceForm.controls.period"
								 [placeholder]="'gl.datePeriod' | translate"
								 [toLocalDate]="true"
								 class="date-range"
								 data-testid="datePeriodRange" size="md">
		</alaris-input-date-range>
		<div class="right">
			<button (click)="load()" alaris-button bType="outline" data-testid="refreshBtn" size="lg">
				<alaris-icon name="icon-refresh-clock-wise"></alaris-icon>
				{{ 'actions.refreshPage' | translate }}
			</button>
		</div>
	</div>
	<div [alarisLoading]="loading$" class="content">
		@if (tRows.length === 0 && !hasFilter) {
			<app-empty-table type="invoices"></app-empty-table>
		} @else {
			<alaris-table (sortingChange)="_applySorting($event)"
						  [dblClickRowAction]="dblClickRowAction.bind(this)"
						  [sorting]="sorting"
						  [tHeaders]="tHeaders"
						  [tRows]="tRows"
						  [templates]="{id, amount, msgFromPack, description, creationDate, operationType}"
						  alarisOverflowFader>
			</alaris-table>
		}
	</div>
	<div class="footer">
		<alaris-pagination (page)="_pageChange($event)"
						   [activePage]="activePage"
						   [hidden]="total === 0"
						   [pageSizeOptions]="pageSizeOptions"
						   [pageSize]="pageSize"
						   [total]="total"
						   class="pagination">
		</alaris-pagination>
	</div>
</alaris-card>

<ng-template #id [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="id-column">

		@switch (data.operationType) {
			@case (OperationType.PAYMENT) {
				<div class="wrapper">
					<alaris-icon name="icon-import"></alaris-icon>
				</div>
			}
			@case (OperationType.API) {
				<alaris-icon name="icon-api"></alaris-icon>
			}
			@default {
				<alaris-icon [name]="data.channels.length > 1 ? 'icon-Multi-plan-v2' : cu.icon(data.channels[0])">
				</alaris-icon>
			}
		}
		{{ data.id }}
	</div>
</ng-template>

<ng-template #amount [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<span [style.color]="data.amount > 0 ? 'var(--as-TextPositive)' : 'var(--as-TextNegative)'">
		{{ data.amount > 0 ? '+' : null }}
		{{ data.amount | currency: bs.currencyCode }}
	</span>
</ng-template>

<ng-template #msgFromPack [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.msgFromPack ? data.msgFromPack : null }}
</ng-template>

<ng-template #description [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	<div class="description-column">
		<div class="description">{{ data.description }}</div>
		@if ((data.operationType === OperationType.CAMPAIGN || data.operationType === OperationType.API) &&
		([CP_PERMISSIONS.CAMPAIGNS_R] | alarisPBAC)) {
			<alaris-link (click)="openCampaignDetailsPanel(data)" data-testid="showDetailsLink">
				{{ 'invoices.showDetails' | translate }}
			</alaris-link>
		}
	</div>
</ng-template>

<ng-template #creationDate [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ data.creationDate | date: 'd MMM y, HH:mm' }}
</ng-template>

<ng-template #operationType [alarisCellCtx]="tRows[0]" let-data="data" let-key="key">
	{{ ('enums.' + data.operationType) | translate }}
</ng-template>

