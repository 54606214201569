import { Injectable } from '@angular/core';
import {
	AlarisTableStateService,
	filterWildcardFn,
	LocalTableService,
	LocalTableUtils
} from '@campaign-portal/components-library';
import { exist } from '@campaign-portal/namespace/common/id';
import { Campaign } from '@campaign-portal/namespace/entities/campaigns/specs';
import { CampaignsService } from '../services/campaigns.service';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterInterval, RPCRequestParams } from '@campaign-portal/namespace/common/rpc.params';
import { MessageTemplatesService } from '../services/message-templates.service';
import { CampaignUtilsService } from '../services/campaign-utils.service';

@Injectable()
export class CampaignsTableService extends LocalTableService<Campaign<exist>> {

	constructor(
		service: CampaignsService,
		stateService: AlarisTableStateService,
		private readonly templates: MessageTemplatesService,
		private readonly campaignUtils: CampaignUtilsService
	) {
		super(service, stateService);
	}

	applyFilters(
		result: ReadResponse<Campaign<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<Campaign<exist>[]> {
		const filters = params.Filters;
		if ( filters === undefined ) {
			return result;
		} else {
			let data = result.Data;
			filters.forEach((filter) => {

				switch (filter.Field) {
					case 'campaign':
						data = data.filter((item) => {
							return filterWildcardFn(item.info.name || '', filter.Value as string);
						});
						break;
					case 'template':
						data = data.filter((item) => {
							if ( item.setup.native ) {
								return !!item.setup.channels.find((ch) => {
									const template = ch.message?.text
										? ch.message.text
										: this.templates.map.get(ch.message?.templateID)?.messageTemplate || '';
									return filterWildcardFn(template, filter.Value as string);
								});
							} else {
								return filterWildcardFn(item.setup.file?.file.name || '', filter.Value as string);
							}
						});
						break;
					case 'status':
						data = data.filter((item) => {
							return (filter.Value as string[]).includes(item.info.status);
						});
						break;
					case 'cost':
						data = data.filter((item) => {
							if ( filter.Value ) {
								const start = (filter.Value as FilterInterval<number | null>).Start;
								const end = (filter.Value as FilterInterval<number | null>).End;
								const more = start !== null && item.statistics?.cost !== undefined
									? item.statistics.cost >= start
									: true;
								const less = end !== null && item.statistics?.cost !== undefined
									? item.statistics.cost <= end
									: true;
								return more && less;
							}
							return true;
						});
						break;
					case 'lastUpdated':
					case 'startDate':
					case 'finishedDate':
					case 'scheduled':
						data = data.filter(item => {
							if ( filter.Value ) {
								const obj = filter.Field === 'lastUpdated' ? item.audit : item.info;
								const start = (filter.Value as FilterInterval<string | null>).Start;
								const end = (filter.Value as FilterInterval<string | null>).End;
								const more = start !== null && obj
									? new Date((obj as Record<string, string>)[filter.Field]) >= new Date(start)
									: true;
								const less = end !== null && obj
									? new Date((obj as Record<string, string>)[filter.Field]) <= new Date(end)
									: true;
								return more && less;
							}
							return true;
						});
						break;
					case 'progress':
					case 'recipients':
					default:
						break;
				}
			});
			return { Success: true, Total: data.length, Data: data };
		}
	}

	applySorting(
		result: ReadResponse<Campaign<exist>[]>,
		params: RPCRequestParams
	): ReadResponse<Campaign<exist>[]> {
		const sorting = params.Sorting?.reverse();
		if ( !sorting ) {
			return result;
		} else {
			let data = result.Data;
			sorting.forEach((sort) => {
				data = data.sort((a, b) => {
					let first: any;
					let second: any;
					switch (sort.Field) {
						case 'campaign':
							first = a.info.name;
							second = b.info.name;
							break;
						case 'template':
							break;
						case 'status':
							first = a.info.status;
							second = b.info.status;
							break;
						case 'progress':
							first = this.campaignUtils.calculatePercentage(a) || 0;
							second = this.campaignUtils.calculatePercentage(b) || 0;
							break;
						case 'cost':
							first = a.statistics?.cost;
							second = b.statistics?.cost;
							break;
						case 'lastUpdated':
						case 'startDate':
						case 'finishedDate':
						case 'scheduled': {
							const objA = (sort.Field === 'lastUpdated' ? a.audit : a.info) as Record<string, string>;
							const objB = (sort.Field === 'lastUpdated' ? b.audit : b.info) as Record<string, string>;
							first = objA[sort.Field] ? new Date(objA[sort.Field]) : '';
							second = objB[sort.Field] ? new Date(objB[sort.Field]) : '';
							break;
						}
						case 'recipients':
						default:
							break;
					}

					return LocalTableUtils.sortComparisonFn(sort.Dir, first, second);
				});
			});
			return { Data: data, Total: result.Total, Success: true };
		}
	}

}
