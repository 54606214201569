<div [class]="type" class="img"></div>
<div class="title">{{ title | translate }}</div>
<div class="sub-title">{{ subTitle | translate }}</div>
<div class="in-row">
	<button *ngIf="buttonText"
			(click)="clicked()"
			[disabled]="disabled"
			alaris-button
			bType="primary" data-testid="emptyTableBtn"
			size="lg">
		<alaris-icon [name]="icon"></alaris-icon>
		{{ buttonText | translate }}
	</button>
	<button *ngIf="additionalButtonText"
			(click)="additionsClicked()"
			[disabled]="disabled"
			alaris-button
			bType="outline" data-testid="emptyTableAdditionalBtn"
			size="lg">
		<alaris-icon [name]="additionalIcon"></alaris-icon>
		{{ additionalButtonText | translate }}
	</button>
</div>
