import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild
} from '@angular/core';

import {
	AlarisComplexTableComponent,
	AlarisDialogService,
	AlarisEditPanelService,
	AlarisProfileService,
	AlarisTableSettingsService,
	AlarisTableStateService,
	ChannelUtilsService,
	DEFAULT_BUTTONS,
	EditPanelWidth,
	PROFILE_SERVICE_INJECTOR,
	RowActionSimple,
	SharedTemplatesService,
	TableFiltersIndicator,
	TableSortIndicator
} from '@campaign-portal/components-library';
import { TagMode } from '@campaign-portal/components-library/lib/tag/src/base';

import { TableTypes } from '@campaign-portal/namespace/entities/table-settings/specs';
import { SenderSubscription } from '@campaign-portal/namespace/entities/sender-id/specs';
import { EnabledDisabledStatus, SenderSubscriptionEventStatus } from '@campaign-portal/namespace/common/enums';
import { SortDirection } from '@campaign-portal/namespace/common/rpc.params';

import { CP_PERMISSIONS } from '@helpers/types/permissions';
import { EnumsMapperService } from '@helpers/services/enums.service';
import { SenderFieldsService } from './sender-fields.service';
import { SendersService } from '../services/senders.service';
import { SenderRequestComponent } from './sender-request/sender-request.component';
import { SenderViewComponent } from './sender-view/sender-view.component';
import { SenderDialogComponent, SendersDialogType } from './sender-dialog/sender-dialog.component';
import { SendersTableService } from './senders.table.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EntityField } from '@campaign-portal/namespace/common/entityField';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-senders',
	templateUrl: './senders.component.html',
	styleUrls: [
		// eslint-disable-next-line max-len
		'../../../../../node_modules/@campaign-portal/components-library/src/assets/templates/table-complex/complex-table.component.scss',
		'./senders.component.scss'
	],
	providers: [SenderFieldsService, SendersTableService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendersComponent extends AlarisComplexTableComponent<SenderSubscription>
	implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('enabled') readonly enabledTemplate!: TemplateRef<any>;
	@ViewChild('activeTill') readonly activeTillTemplate!: TemplateRef<any>;
	@ViewChild('lastUpdated') readonly lastUpdatedTemplate!: TemplateRef<any>;
	@ViewChild('lastEvent') readonly lastEventTemplate!: TemplateRef<any>;

	readonly Status = EnabledDisabledStatus;
	readonly CP_PERMISSIONS = CP_PERMISSIONS;

	override sorting: TableSortIndicator = new Map()
		.set('senderId', { enabled: true, value: SortDirection.ASC });

	override dropDownRowActions: RowActionSimple<any>[] = [
		{
			icon: '',
			label: 'actions.view',
			action: this.openViewPanel.bind(this)
		},
		{
			icon: '',
			label: 'actions.unsubscribe',
			action: this.unSubscribeRequest.bind(this),
			disabled: (row: SenderSubscription): boolean => row.lastEvent !== SenderSubscriptionEventStatus.CONFIRMED
		},
		{
			icon: '',
			label: 'actions.cancel',
			action: this.cancelRequest.bind(this),
			disabled: (row: SenderSubscription): boolean => row.lastEvent !== SenderSubscriptionEventStatus.NEW
		}
	];
	override ctxActions = this.dropDownRowActions;
	override readonly id = 'senders';

	constructor(
		public override readonly dataService: SendersTableService,
		public readonly sendersService: SendersService,
		public override readonly fieldsService: SenderFieldsService,
		public override readonly tableSettingsService: AlarisTableSettingsService,
		public override readonly editPanel: AlarisEditPanelService,
		public override readonly cd: ChangeDetectorRef,
		private readonly dialog: AlarisDialogService,
		private readonly senderTemplates: SharedTemplatesService,
		private readonly enums: EnumsMapperService,
		public readonly cu: ChannelUtilsService,
		stateService: AlarisTableStateService,
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profileService: AlarisProfileService
	) {
		super(
			dataService,
			fieldsService,
			tableSettingsService,
			editPanel,
			cd,
			TableTypes.SENDERS,
			DEFAULT_BUTTONS,
			stateService
		);
		this.dataService.stateID = this.id;

		if ( !profileService.allowed([CP_PERMISSIONS.SENDERS_E]) ) {
			this.removeActions();
		}
	}

	override dblClickRowAction(senderId?: SenderSubscription): void {
		this.openViewPanel(senderId);
	}

	override ngOnInit(): void {
		this.sendersService.refresh$.next();
		super.ngOnInit();

		// if ( this.route.snapshot.queryParamMap.get('mode') === 'addSenderId' ) {
		// 	this.openRequestPanel();
		// }
	}

	override applyHeaders(tHeaders: EntityField[]): void {
		super.applyHeaders(tHeaders);
		this.setRefBooksInFields({
			lastEvent: {
				list$: new BehaviorSubject<string[]>(Object.keys(this.enums.get('SenderSubscriptionEventStatus')))
			},
			enabled: {
				list$: new BehaviorSubject<string[]>(Object.keys(this.enums.get('EnabledDisabledStatus')))
			}
		});
	}

	override applyFilter(event: TableFiltersIndicator): void {
		super.applyFilter(event);
		this.readParams.Filters?.forEach(f => f.Field === 'lastEvent' ?
			f.Value = (f.Value as string[]).map(i => this.enums.get('SenderSubscriptionEventStatus')[i]) : f
		);
		this.readParams.Filters?.forEach(f => f.Field === 'enabled' ?
			f.Value = (f.Value as string[]).map(i => this.enums.get('EnabledDisabledStatus')[i]) : f
		);
	}

	override ngAfterViewInit(): void {
		super.ngAfterViewInit();
		this.senderTemplates.share({
			enabled: this.enabledTemplate,
			activeTill: this.activeTillTemplate,
			lastUpdated: this.lastUpdatedTemplate,
			lastEvent: this.lastEventTemplate
		});
	}

	override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.senderTemplates.clear();
		this.completeRefBooksInFields(['lastEvent', 'enabled']);
	}

	removeActions(): void {
		this.dropDownRowActions = this.ctxActions = [
			{
				icon: '',
				label: 'actions.view',
				action: this.openViewPanel.bind(this)
			}
		];
	}

	openRequestPanel(): void {
		this.editPanel.open(SenderRequestComponent, EditPanelWidth.SM, {})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => this.load());
	}

	openViewPanel(senderId?: SenderSubscription): void {
		this.editPanel.open(SenderViewComponent, EditPanelWidth.SM, {
			senderId
		})
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((action) => {
				switch (action) {
					case 'Unsubscribe':
						this.unSubscribeRequest(senderId);
						break;
					case 'Cancel':
						this.cancelRequest(senderId);
						break;
					default:
						break;
				}
			});
	}

	cancelRequest(senderId?: SenderSubscription): void {
		this.openSpecificDialog('Cancel', senderId);
	}

	unSubscribeRequest(senderId?: SenderSubscription): void {
		this.openSpecificDialog('Unsubscribe', senderId);
	}

	eventMode(lastEvent?: SenderSubscriptionEventStatus): TagMode {
		switch (lastEvent) {
			case SenderSubscriptionEventStatus.CONFIRMED:
				return 'success';
			case SenderSubscriptionEventStatus.CANCELLED:
			case SenderSubscriptionEventStatus.DECLINED:
				return 'error';
			case SenderSubscriptionEventStatus.IN_PROGRESS:
				return 'gray';
			case SenderSubscriptionEventStatus.NEW:
			case SenderSubscriptionEventStatus.PERIOD_CHANGED:
			case SenderSubscriptionEventStatus.UNSUBSCRIBED:
			default:
				return 'white';
		}
	}

	override refresh(): void {
		this.dataService.firstLoad = true;
		this.load();
	}

	private openSpecificDialog(type: SendersDialogType, senderId?: SenderSubscription): void {
		this.dialog.open(SenderDialogComponent, { data: { senderId, type }, autoFocus: false })
			.closed
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result) => {
				if ( result ) {
					this.load();
				}
			});
	}
}
