import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { AbstractCRUDService } from '@campaign-portal/components-library';

import { EntityField, InputComplexType, InputType } from '@campaign-portal/namespace/common/entityField';
import { ReadResponse } from '@campaign-portal/namespace/common/implementations';
import { FilterType } from '@campaign-portal/namespace/common/rpc.params';
import { TrafficType } from '@campaign-portal/namespace/common/enums';

@Injectable()
export class SenderFieldsService implements AbstractCRUDService, OnDestroy {

	entity = '';
	title = '';
	readonly loading$ = new BehaviorSubject<boolean>(false);

	private readonly headers: EntityField[] = [
		{
			id: 0,
			name: 'columns.senderID',
			variable: 'name',
			required: true,
			type: InputType.TEXT,
			readonly: true,
			filterType: FilterType.LIKE
		},
		{
			id: 1,
			name: 'columns.state',
			variable: 'enabled',
			required: true,
			type: InputComplexType.MULTISELECT,
			readonly: true,
			data: [],
			filterType: FilterType.IN
		},
		{
			id: 2,
			name: 'columns.channel',
			variable: 'trafficType',
			required: true,
			type: InputComplexType.MULTISELECT,
			readonly: true,
			data: [TrafficType.SMS, TrafficType.VIBER],
			filterType: FilterType.IN
		},
		{
			id: 3,
			name: 'columns.activeTill',
			variable: 'activeTill',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		},
		{
			id: 4,
			name: 'columns.lastEvent',
			variable: 'lastEvent',
			required: true,
			type: InputComplexType.MULTISELECT,
			readonly: true,
			data: [],
			filterType: FilterType.IN
		},
		{
			id: 5,
			name: 'columns.lastUpdated',
			variable: 'lastUpdated',
			required: true,
			type: InputComplexType.DATE_RANGE,
			readonly: true,
			filterType: FilterType.BETWEEN
		}
	];

	constructor() {
	}

	ngOnDestroy(): void {
		this.loading$.complete();
	}

	create(): Observable<void> {
		return of(undefined);
	}

	delete(): Observable<void> {
		return of(undefined);
	}

	read(): Observable<ReadResponse<EntityField[]>> {
		return of({
			Success: true,
			Total: this.headers.length,
			Data: this.headers
		});
	}

	update(): Observable<void> {
		return of(undefined);
	}
}
