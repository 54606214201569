import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { Plan } from '@campaign-portal/namespace/entities/subscriptions/specs';
import { AlarisProfileService, PROFILE_SERVICE_INJECTOR } from '@campaign-portal/components-library';

import { PurchaseService, SubscribedPackagesStore } from '../../finance/purchase/purchase.service';
import { CP_PERMISSIONS } from '@helpers/types/permissions';

@Component({
	selector: 'app-overview',
	templateUrl: './overview.component.html',
	styleUrls: ['./overview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent implements OnInit, OnDestroy {
	readonly CP_PERMISSIONS = CP_PERMISSIONS;
	packs: SubscribedPackagesStore = new Map();
	plans: Plan[] = [];

	readonly loading$ = new BehaviorSubject<boolean>(true);
	private readonly ngUnsubscribe = new Subject<void>();

	constructor(
		@Inject(PROFILE_SERVICE_INJECTOR) public readonly profile: AlarisProfileService,
		private readonly purchaseService: PurchaseService,
		private readonly router: Router,
		private readonly cd: ChangeDetectorRef
	) {
	}

	ngOnInit(): void {
		this.purchaseService.subscribed()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(subscribed => {
				[this.packs, this.plans] = [subscribed.Data.packs, subscribed.Data.plans];
				this.loading$.next(false);
				this.cd.markForCheck();
			});
	}

	addPurchase(): void {
		this.router.navigate(['finance', 'purchase']);
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
		this.loading$.complete();
	}
}
